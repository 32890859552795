// Font
$font-family: "Raleway", sans-serif;
$font-family-alt: "Luckiest Guy", sans-serif;
$font-size: 16px;
$font-size-small: 14px;
$font-weight: 300;

// Heads
$h1-size: 48px;
$h2-size: 42px;
$h3-size: 32px;
$h4-size: 24px;

// Colors
$primary-color: #ed5040; // Red
$primary-color-hover: #da3f2f;

$primary-color: #23a300; // Green
$primary-color-hover: #1b7b01;

$black-color: #444 ; // Grey/Black
$grey-color: #c5c5c5 ; // Light grey

$linear-gradient: linear-gradient(to right, #23a300 0%, #1b7b01 100%); // Green gradient
$linear-gradient-black: linear-gradient(to right, #2a2a2a 0%, #191919 100%); // Black gradient

$paragraph-color: #474747;

$bg-color-grey: #f7f7f7;
$bg-color-black: #444;

$placeholder-color: #c5c5c5;

// Animations
$transition: ease all 0.4s;

// Font path
$fa-font-path: "assets/fonts/";
