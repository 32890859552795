/* ------ =Forms ------ */

.wpcf7-form {
  margin-top: -5px;
  .btn-primary {
    padding: 20px 30px;

    &:hover {
      background-color: $primary-color-hover;
    }
  }
}

.form-control {
  height: 60px;
  margin-top: 5px;
  border-radius: 0px;
  padding: 20px 25px;
  box-shadow: none;
  border: #fff;
  max-width: 700px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.04);

  &:focus {
    border-color: $bg-color-black;
    box-shadow: none;
  }
}

textarea.form-control {
    height: auto;
    min-height: 200px;
    padding: 20px 20px;
}

/* ------ =Validation ------ */

div.screen-reader-response {
  display: none;
}

.has-error .form-control {
    border-color: $primary-color;
}

.alert-warning, .alert-danger {
    border: none;
    border-radius: 0px;
    font-size: 14px;
    color: $primary-color;
    background-color: $bg-color-grey;
    border: 1px solid $primary-color;
    border-radius: 0px;
    margin-top: 10px;
    max-width: 700px
}
