/* ================================
=Desktop
================================ */

.featured {

  h2 {
    margin-bottom: 40px;
    font-family: $font-family-alt;
  }

  a.feature {
    display: block;

    .sphere-wrap {
      padding: 2px;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      margin-bottom: 10px;
      background: $linear-gradient;

      .sphere {
        height: 200px;
        width: 200px;
        background: #424242;
        border-radius: 50%;
        background: #fff;
        transition: $transition;
        overflow: hidden;
        border: 10px solid #fff;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    h4 {
      margin: 5px 0 15px 0;
      font-size: $h4-size;
      color: $paragraph-color;
      font-weight: 600;
    }

    p {
      margin: 15px 0;
    }

    p.button {
      font-weight: 600;
      display: block;
      color: $primary-color;

      i {
        margin-left: 5px;
        transition: $transition;
      }
    }

    &:hover {
      text-decoration: none;

      .sphere-wrap {
        border-radius: 100%;
        display: inline-block;
        position: relative;
        background: $linear-gradient;
        margin-bottom: 10px;

        .sphere {
          background: #424242; border-radius: 50%;
          background: $linear-gradient;
          border: 10px solid $primary-color;

          i {
            padding: 46px 0;
            font-size: 46px;
            color: #fff;
          }
        }
      }

      i.fa-arrow-right {
        margin-left: 10px;
      }
    }
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  .featured {
    a.feature {
      .sphere-wrap {
        .sphere {
          height: 120px;
          width: 120px;
          background: #424242; border-radius: 50%;
          background: #fff;
          transition: $transition;

          i {
            padding: 45px 0;
            font-size: 32px;
          }
        }
      }

      h4 {
        margin: 10px 0 15px 0;
        font-size: 20px;
      }

      &:hover {
        text-decoration: none;

      .sphere-wrap {
          i {
            padding: 42px 0;
            font-size: 36px;
          }
        }
      }

      i.fa-arrow-right {
        margin-left: 10px;
      }
    }
  }

} /* End of view */
