/* ================================
=Desktop
================================ */

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 200px;
  line-height: 200px;
  background-color: #2a2a2a;
  background: rgba(0, 0, 0, 0) linear-gradient(to right, #2a2a2a 0%, #191919 100%) repeat scroll 0 0;

  .footer-left, .footer-right {
    text-align: left;

    ul {
      list-style-type: none;
      margin: 0;
      padding: 40px 0;
      li {
        line-height: 30px;
        color: #fff;
      }
    }

    a {
      color: #fff;
      font-weight: 400;
    }
  }

  .footer-right {
    text-align: right;
  }

  .center {
    a {
      padding: 0;
      display: inline-block;

      i {
        color: #fff;
        opacity: 0.75;
        font-size: 30px;
        transition: $transition;
      }

      &:hover {
        i {
          opacity: 1;
        }
      }
    }
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  .footer {
    position: relative;
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    height: auto;
    line-height: 20px;
    text-align: center;

    .footer-left, .footer-right, .center {
      text-align: center;
      line-height: 140px;

      img {
        padding: 0;
      }
    }
  }

} /* End of view */


/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

  .footer {
    .footer-left, .footer-right, .center {
      line-height: 0px;
    }
  }

} /* End of view */
