/* ================================
=Desktop
================================ */

section.image-fullwidth-one {
  background-color: transparent;
  background-position: center;
  background-size: cover;
  transform: translateX(-180deg);
  position: relative;
  height:400px;
  padding: 0;
  -webkit-backface-visibility: hidden;
  background-attachment: fixed;

  .picture-overlay {
    @include overlay;
  }

  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 400px;
    position: relative;
    z-index: 1;
    text-align: center;

    .inner-content {
      padding: 15px;
      text-align: center;

      h3 {
        color: #fff;
        margin: 0;
        padding: 0;
        line-height: 100%;
        width:100%;
        font-size: 42px;
        font-weight: 700;
        display: inline-block;
        position: relative;
      }

      a.hvr-sweep-to-right {
        @include sweeptoright;
        padding: 17px 30px;
        font-size: 12px;

        &:hover {
          color: #fff;
        }
      }

      a.hvr-sweep-to-right:before{
        background-color: $primary-color-hover;
      }
    }
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  section.image-fullwidth-one {
    height:200px;

    .inner {
      height: 200px;

      .inner-content {
        h3 {
          font-size: 24px;
        }

        a.hvr-sweep-to-right {
          @include sweeptoright;
          font-size: 12px;
          padding: 17px 30px;
          margin-top: 20px;
        }
      }
    }
  }

} /* End of view */
