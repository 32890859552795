/* ================================
=Desktop
================================ */

.push {
  height: 90px;
}

.current-post-parent {
  li.current-menu-item {
    display: none;
  }
}

.navbar-default {
  background-color: rgba(0,0,0,1);
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-transition: $transition;
  transition: $transition;

  .navbar-nav {
    line-height: 60px;
    float: right;
    -webkit-transition: $transition;
    transition: $transition;

    li>a {
      background-color: transparent;
      line-height: 60px;
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
      position: relative;
      margin: 0 16px;
      padding-right: 0;
      padding-left: 0;
      color: #fff;
      transition: $transition;

      &:before, &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        -webkit-transform: scale3d(0, 1, 1);
        transform: scale3d(0, 1, 1);
        -webkit-transform-origin: center left;
        transform-origin: center left;
        -webkit-transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
        transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
      }

      &:hover {
        color: #fff;
      }

      &:before {
        -webkit-transition-delay: 0.2s;
        transition-delay: 0.2s;
        background: rgba(0, 0, 0, 0) $linear-gradient repeat scroll 0 0;
      }

      &:after {
        background: $primary-color;
      }
    }

    .active {

      a:hover, a:focus {
        background-color: transparent;
        color: #fff;
      }

      a {
        &:before, &:after {
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
        }

        &:before {
          -webkit-transition-delay: 0s;
          transition-delay: 0s;
        }
      }
    }

    .active, :hover, :focus, :visited {
      a {
        background-color: transparent;
      }

      &:hover, &:focus {
        &:before {
          -webkit-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
          -webkit-transition-delay: 0s;
          transition-delay: 0s;
        }
      }
    }

    .active {
      a {
        color: #fff;
      }
    }

    .open>a {
      color: $primary-color;
    }
  }

  .navbar-brand {
    -webkit-transition: $transition;
    transition: $transition;
    padding: 0;
    display: inline-block;
    height: 90px;
    line-height: 90px;

    img {
      display: inline-block;
      transition: $transition;
      max-width: 80px;
    }
  }
}

/* ----- =Navbar-toggle ------ */

.navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background: none;
}

.navbar-toggle {
  position: absolute;
  right: 15px;
  top: 8px;
  margin: 0;
  padding: 14px 20px 20px 10px;
  background-color: transparent;
  background-image: none;
  border: none;

  // styles to `:focus`.
  &:focus {
    outline: 0;
  }

  // Bars
  .icon-bar {
    position: absolute;
    display: block;
    top: 6px;
    right: 0px;
    width: 28px;
    height: 3px;
    border-radius: 1px;
    -webkit-transition: all ease-in-out 0.3s;
    -o-transition: all ease-in-out 0.3s;
    transition: all ease-in-out 0.3s;

  }
  .icon-bar:nth-child(2){
    margin-top: 4px;
    background-color: #f2f2f2;
  }
  .icon-bar:nth-child(3) {
    margin-top: 10px;
    background-color: #fff;
  }
  .icon-bar:nth-child(4) {
    margin-top: 16px;
    background-color: #fff;
  }
}

body.nav-open {
  .navbar-toggle {
    .icon-bar {
      -webkit-transition: all ease-in-out 0.3s;
      -o-transition: all ease-in-out 0.3s;
      transition: all ease-in-out 0.3s;
    }
    .icon-bar:nth-child(2){
      background-color: transparent;
    }
    .icon-bar:nth-child(3) {
      top: 8px;
      transform: rotate(45deg);
    }
    .icon-bar:nth-child(4) {
      top: 2px;
      transform: rotate(-45deg);
    }
  }
}



/* ----- =Dropdown ------ */

.navbar-default {
  ul.navbar-nav {
    .open {

      &:hover {
        border: none;

        li>a {
          text-decoration: none;
        }

        a {
          border: none;
          color: #fff;
        }
      }

      a {
        border: none;
        background-color: transparent;

        &:hover, &:focus {
          border: none;
          background-color: transparent;
          color: $primary-color;

          &:before, &:after {
            display: none;
          }
        }
      }
    }

    ul.dropdown-menu {
      border: none;
      background-color: $primary-color;
      border-radius: 0px;
      box-shadow: none;
      padding: 0;
      list-style-position: inside;

      li {
        padding: 0;
        width: 100%;
        display: block;

        a {
          font-size: 13px;
          color: #fff;
          background-color: $primary-color;
          padding: 0 20px;
          margin: 0;
          width: 100%;
          display: block;

          &:hover {
            color: #fff;
            background-color: $primary-color-hover;
          }
        }
      }
    }
  }
}

/* ----- =Scrolled ------ */

.navbar-default.navbar-fixed-top {
  height: 90px;
}

.navbar-default.navbar-fixed-top.scrolled {
  height: 70px;

  .navbar-nav {
    > li > a {
      line-height: 60px;
      padding-top: 7px;
      padding-bottom: 3px;
    }
  }

  .navbar-brand {
    line-height: 70px;
    height: 70px;

    img {
      max-width: 60px;
    }
  }
}

ul.dropdown-menu {
  transition: $transition;
  display: inline-block;
  margin-top: -200px !important;
  position: absolute;
  z-index: -2;
  opacity: 0;
}

.open {
  ul.dropdown-menu {
    display: inline-block;
    margin-top: 0px !important;
    position: absolute;
    opacity: 1;
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  header {
    .navbar {
      &.navbar-default {
        &.navbar-fixed-top {
          .container {
            .navbar-header {
              float: none;
              margin: 0 -15px;

              .navbar-toggle {
                display: block;
                &.collapsed {

                }
              }

              .navbar-brand {
                margin: 0;
                height: 50px;
                line-height: 50px;
                padding-left: 15px;

                img {
                  max-width: 46px;
                  margin-top: -2px;
                }
              }
            }
            .navbar-collapse {
              max-height: inherit;
              margin-right: -33px;
              margin-left: -25px;
              padding: 0;
              background-color: $primary-color;

              &.collapse {
                display: none!important;

                &.in {
                  display:block !important;
                }
              }

              .nav {
                &.navbar-nav {
                  line-height: 30px;
                  float: inherit;
                  margin: 0;

                  li {
                    float: none;
                    a {
                      line-height: 35px;
                      padding: 5px 10px;
                      color: #fff;
                      font-size: 13px;
                      display: block;

                      &:before, &:after {
                        display: none;
                      }
                    }
                  }

                  .active {
                    a, a:hover {
                      color: #fff;
                      border: none;
                      background-color: transparent;

                      &:before, &:after {
                        display: none;
                      }
                    }
                  }

                  &:hover {
                    a {
                      border: none;
                      color: #fff;
                    }
                  }

                  ul.dropdown-menu {
                    background-color: $primary-color-hover;
                    padding: 5px 30px;
                    position: relative;
                    z-index: -1;
                    opacity: 0;
                    top: 0;
                    transition: $transition;
                    width: 100%;
                    display: none;

                    li {
                      &:hover {
                        a {
                          color: #fff;
                        }
                      }
                    }
                  }

                .open {
                    a {
                      color: #fff;
                    }

                    &:hover a {
                      color: #fff;
                    }

                    ul.dropdown-menu {
                      z-index: 1;
                      top: 0;
                      opacity: 1;
                      display: inline-block;

                      li {
                        a {
                          color: #fff;
                          line-height: 20px;
                          background-color: $primary-color-hover;

                          &:hover, &focus {
                            color: #fff;
                          }
                        }
                      }

                      .active {
                        a {
                          background-color: $primary-color-hover;
                          color: #fff;
                          line-height: 30px;
                        }
                        &:hover {
                          a {
                            background: transparent;
                            color: #fff;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    // Scrolled

    .navbar-default {
      &.navbar-fixed-top {
        height: 50px;
      }

      &.navbar-default {
        &.navbar-fixed-top {
          &.scrolled {
            height: 50px;

            .nav {
              &.navbar-nav {
                > li > a {
                  line-height: 35px;
                  padding: 10px 0px;
                  color: #fff;
                  font-size: 13px;
                }

                .navbar-brand {
                  line-height: 20px;
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }

  .push {
    height: 50px;
  }
} /* End of view */
