/* ================================
=Desktop
================================ */

.content, .content-two {
  padding: 90px 0;
  background-color: $bg-color-grey;

  .container {
    .intro-image {
      border: 15px solid #fff;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.18);
      height: 380px;
      object-fit: cover;
    }

    .padding-content {
      padding: 20px 30px;

      h3 {
        font-size: $h2-size;
        font-family: $font-family-alt;
        font-weight: 300;
      }

      .btn-primary {
        padding: 20px 30px;
        font-weight: 600;
      }
    }
  }
}

.content-two {
  background-color: #fff;
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  .content, .content-two {
    padding: 60px 0;

    .container {
      .intro-image {
        border: 10px solid #fff;
        height: 220px;
      }

      .padding-content {
        padding: 0px 20px;

        h3 {
          font-size: 28px;
          font-family: $font-family-alt;
        }

        .btn-primary {
          padding: 20px 30px;
          font-weight: 600;
        }
      }
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 425px)
================================ */

@media screen and (max-width: 425px) {

  .content, .content-two {
    padding: 60px 0;

    .container {
      text-align: center;

      .intro-image {
        border: 10px solid #fff;
        height: 220px;
      }

      .padding-content {
        padding: 0px 20px;

        h3 {
          font-size: 28px;
          font-family: $font-family-alt;
          margin-top: 30px;
        }

        .btn-primary {
          padding: 20px 30px;
          font-weight: 600;
        }
      }
    }
  }

  .content-two {
    .container {
      .intro-image {
        margin-top: 30px;
      }

      .padding-content {
        h3 {
          margin-top: 0;
        }
      }
    }
  }

} /* End of view */
