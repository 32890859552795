/* ================================
=Desktop
================================ */

.news {
  background-color: $linear-gradient;

  .home {

    .row {
      .newswrap {
        &:last-child {
          display: none;
        }
      }
    }
  }

  .thumbnail {
    display: block;
    border: none;
    padding: 0;
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.04);
    margin-bottom: 40px;
    text-align: center;
    position: relative;

    .newsimage {
      width: 100%;
      height: 210px;
      margin-right: 45px;
      position: relative;
      overflow: hidden;
      display: block;

      &:before {
        @include overlay;
      }

      img {
        height: 100%;
        max-width: 100%;
        object-fit: cover;
        position: relative;
        z-index: 0;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transition: ease 500ms;
        -webkit-backface-visibility: hidden;
      }
    }

    &:hover {
      text-decoration: none;
      img {
        transform: scale(1.2,1.2);
      }

      h4 {
        color: $primary-color;
        text-decoration: none;
      }
    }

    .caption {
      background-color: #fff;
      padding: 30px 30px 20px 30px;
      position: relative;
      z-index: 1;

      p.date {
        font-size: 13px;
        color: #fff;
        top: -26px;
        left: 50%;
        font-weight: 700;
        width: 140px;
        padding: 7px 15px;
        margin: 10px 0 0 -70px;
        position: absolute;
        background: rgba(0, 0, 0, 0)  $linear-gradient repeat scroll 0 0;
      }

      p.exerpt {
        min-height: 85px;

        span {
          color: $primary-color;
          font-weight: 600;
          display: block;
        }
      }

      .titledate {
        min-height: 60px;
        padding-bottom: 10px;

        h4{
          font-size: $h4-size;
          font-weight: 700;
          margin: 15px 0 5px 0;
          min-height: 55px;

          a {
            color: $paragraph-color;
          }
        }
      }
    }
  }

  .news-single {
    text-align: left;
    padding-right: 30px;

    h3 {
      text-align: left;
      margin-bottom: 0;

      &:after {
        left: 0;
        margin: 10px 0px 20px 0;
      }
    }

    img#single-image {
      margin: 20px 0;
      width: 100%;
      object-fit: cover;
    }

    p.date {
      opacity: 0.5;
    }
  }
}

/* ================================
=Small desktop (max. 1200px)
================================ */

@media screen and (max-width: 1200px) {

  .news {
    .thumbnail {
      .caption {
        p.exerpt {
          min-height: 115px;
        }
      }
    }

    .news-single {
      padding-right: 15px;
    }
  }

} /* End of view */

/* ================================
=Tablet (max. 992px)
================================ */

@media screen and (max-width: 992px) {

  .news {
    .home {
      .row {
        .thumbwrap {
          &:last-child {
            display: inline-block;
          }
        }
      }
    }

    .thumbnail {
      .caption {
        .titledate {
          h4 {
            font-size: 22px;
          }
        }
      }
    }
  }

} /* End of view */

/* ================================
=Mobile (max. 768px)
================================ */

@media screen and (max-width: 768px) {

  .news {
    .home {
      .row {
        .newswrap {
          &:last-child {
            display: block;
          }
        }
      }
    }
  }

} /* End of view */
